@charset "UTF-8";
.gs-wrapper,
svg {
  width: 100%;
  height: 100%;
}

.gs-wrapper,
body,
html,
svg {
  height: 100%;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Share Tech", sans-serif;
  color: #fff;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

.gs-header::after,
.gs-langlist > li::after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: #1ec3ff;
  text-decoration: none;
}

svg g {
  transition: opacity 0.4s ease;
}

svg g.is-blur {
  opacity: 0.2;
}

svg g.is-focus {
  font-weight: 700;
}

svg circle {
  fill: none;
}

svg text {
  font-size: 12px;
  text-anchor: middle;
  fill: #000;
  opacity: 0;
}

.gs-wrapper {
  position: relative;
  min-width: 1023px;
  min-height: 100vh;
  background: radial-gradient(
    farthest-corner at -100px -100px,
    #11769b 0,
    #032d3d 15%,
    #000 100%
  );
}

.gs-chart,
.gs-content,
.gs-nav {
  position: absolute;
}

.gs-header {
  height: 85px;
  padding: 20px;
}

.gs-header::after {
  display: block;
  clear: both;
}

.gs-header-title {
  float: left;
}

.gs-header-title h1 {
  font-size: 31px;
  line-height: 1.1em;
}

.gs-header-title p {
  font-size: 10px;
  margin: 0;
}

.gs-header-menu {
  display: none;
}

.gs-content {
  display: block;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
}

.gs-nav-bg,
.gs-nodata {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gs-nav {
  top: 0;
  left: 0;
  bottom: 0;
  width: 150px;
  padding: 20px;
  z-index: 2;
  backdrop-filter: blur(4px);
}

.gs-chart-inner,
.gs-nodata {
  width: 100%;
  height: 100%;
}

.gs-nav-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.gs-chart {
  top: -30px;
  left: 120px;
  bottom: 70px;
  right: 500px;
  margin: auto;
  z-index: 1;
}

.gs-nodata {
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.gs-footer,
.gs-side {
  position: absolute;
  padding: 20px;
  bottom: 0;
  right: 0;
}

.is-no-data .gs-nodata {
  display: -ms-flexbox;
  display: flex;
}

.is-no-data .gs-chart-inner,
.is-no-data .gs-side {
  display: none;
}

.gs-footer > div,
.gs-side-title > * {
  display: inline-block;
}

.gs-side {
  top: 0;
  width: 500px;
  z-index: 2;
}

.gs-side-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.gs-side-date {
  font-size: 16px;
  margin: 5px;
  opacity: 0.4;
}

.gs-footer {
  left: 0;
  text-align: center;
  z-index: 3;
}

.gs-footer > div + div {
  margin-left: 20px;
}

.gs-langlist-wrap {
  overflow: auto;
  height: calc(100% - 120px);
}

.gs-langlist a {
  display: block;
  cursor: pointer;
  position: relative;
  transition: color 0.4s ease;
  z-index: 1;
}

.gs-langlist > li {
  position: relative;
  padding: 0.1em 0;
  transition: background 0.3s ease-out;
}

.gs-langlist > li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.gs-langlist > li:active {
  background: rgba(255, 255, 255, 0.4);
}

.gs-langlist > li.all {
  border-bottom: 1px solid #1ec3ff;
  margin-bottom: 6px;
}

.gs-langlist > li::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  display: block;
  background: #fff;
  transition: all 0.4s ease;
}

.gs-langlist > li.is-selected::after {
  width: 100%;
}

.gs-langlist > li.is-selected a {
  color: #000;
}

.gs-repolist {
  list-style-type: decimal;
  padding-left: 2.8em;
}

.gs-repolist-wrap {
  overflow: auto;
  height: calc(100% - 120px);
}

.gs-repolist a {
  display: block;
  cursor: pointer;
  padding: 0.1em 0;
}

.gs-repolist > li {
  clear: both;
  position: relative;
}

.gs-repolist-item {
  position: relative;
}

.gs-repolist-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -40px;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.gs-repolist-item:hover::before {
  opacity: 1;
}

.gs-repolist-item.rank1 {
  font-size: 2.5em;
}

.gs-repolist-item.rank2 {
  font-size: 2em;
}

.gs-repolist-item.rank3 {
  font-size: 1.5em;
}

.gs-repolist-star {
  color: #fff;
  float: right;
}

.gs-loading {
  display: none;
  font-size: 24px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 10;
}

.is-loading .gs-loading {
  display: block;
  animation: fadeIn 0.3s ease;
}

.gs-loading p {
  height: 1em;
  line-height: 1em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.fb-like,
.twitter-share-button {
  vertical-align: middle;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {
  .gs-nav,
  .gs-nav-bg {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .gs-wrapper {
    min-width: 0;
    height: auto;
  }

  .gs-header {
    padding: 15px;
  }

  .gs-content {
    position: static;
  }

  .gs-nav {
    display: block;
    position: fixed;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    overflow: auto;
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    transition: transform 0.3s ease;
    z-index: 3;
  }

  .is-menu-open .gs-nav {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  .gs-chart {
    position: static;
    max-width: 100%;
    height: 400px;
    padding: 10px;
  }

  .gs-side {
    position: static;
    padding: 15px;
    width: 100%;
  }

  .gs-side span {
    word-wrap: break-word;
  }

  .gs-nav {
    width: 50%;
  }

  .gs-nav-bg {
    display: none;
    position: fixed;
  }

  .is-menu-open .gs-nav-bg {
    display: block;
  }

  .gs-langlist-wrap {
    height: auto;
  }

  .gs-repolist {
    position: relative;
    left: -10px;
  }

  .gs-repolist-item a {
    padding: 0.3em 0;
  }

  .gs-repolist-item.rank1 {
    font-size: 2em;
  }

  .gs-repolist-item.rank2 {
    font-size: 1.6em;
  }

  .gs-repolist-item.rank3 {
    font-size: 1.3em;
  }

  .gs-header-menu {
    display: inline-flex;
    border: none;
    padding: 0;
    background: none;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    z-index: 4;
  }

  .gs-header-menuclose,
  .gs-header-menuopen {
    width: 100%;
    transition: transform 0.3s ease;
  }

  .gs-header-menuopen {
    position: absolute;
    height: 100%;
    margin: 0;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  .gs-header-menuopen span,
  .gs-header-menuopen::after,
  .gs-header-menuopen::before {
    content: "";
    display: block;
    left: 4px;
    right: 4px;
    height: 3px;
    background: #fff;
    position: absolute;
  }

  .gs-header-menuopen::before {
    top: 6px;
  }

  .gs-header-menuopen::after {
    bottom: 6px;
  }

  .gs-header-menuopen span {
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .is-menu-open .gs-header-menuopen {
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  .gs-header-menuclose {
    position: absolute;
    height: 100%;
    margin: 0;
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  .gs-header-menuclose::after,
  .gs-header-menuclose::before {
    content: "";
    left: 4px;
    right: 4px;
    height: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
  }

  .gs-header-menuclose::before {
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .gs-header-menuclose::after {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .is-menu-open .gs-header-menuclose {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  .gs-footer {
    position: static;
    padding-bottom: 60px;
  }

  .gs-footer > div {
    display: block;
  }

  .gs-footer > div + div {
    margin-left: 0;
    margin-top: 30px;
  }
}
